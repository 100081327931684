$(document).ready(function() {

    'use strict';

    var headerHeight = function () {
        return $('header').height();
    };
    
    var navSize = function () {
        return $('header .navbar').outerHeight();
    };
    
    var fixPoint = function () {
        return headerHeight()-navSize();
    };
    
    var topOffset = function () {
        return $(window).scrollTop();
    };
    var setFixedClass = function () {
        var navbar = $('header navbar.navbar-fixed-top');
        if(navbar.length === 0) {
            $('header .navbar').addClass('navbar-fixed-top');
        }
        return;
    };
    
    var removeFixedClass = function () {
        $('header .navbar').removeClass('navbar-fixed-top');
        return;
    };
    var setContainerPadding = function () {
        $('section').css('padding-top', navSize()+20);
    };
    var handleBar = function () {
        if (topOffset() >= fixPoint()) {
            setFixedClass();
        }
        else {
            removeFixedClass();
        }
    };

    $('ul.primary li a[href^=\'#\']').on('click', function(e) {
        // prevent default anchor click behavior
        e.preventDefault();
        
        // store hash
        var hash = this.hash;

        // animate
        $('html, body').animate({
            scrollTop: $(this.hash).offset().top
            }, 1000, function () {

            // when done, add hash to url
            // (default click behaviour)
            window.location.hash = hash;
        });
    });

    $(window).on('scroll', function () {
        handleBar();
    });  
  
    // initial
    handleBar();

    if ($('body.index').length > 0) {
  
        $(window).resize( function () {
            setContainerPadding();
        });
    
        setContainerPadding();
    }

    if ( $('body.admin').length > 0 ) {

        var sendFile = function (file) {
            var data = new FormData();
            data.append("_token", $('form input[name=_token').val());
            data.append("file", file);

            $.ajax({
                data: data,
                type: "POST",
                url: "/pages/upload",
                cache: false,
                contentType: false,
                processData: false,
                success: function(url) {
                    $('.text-editor').summernote('editor.insertImage', url);
                }
            });
        };

        $('.text-editor').summernote({
            onImageUpload: function(files) {
                sendFile(files[0]);
                },
            lang: 'de-DE',
            height: 300,
            toolbar: [
                ['insert', ['link', 'picture']],
                ['style', ['style', 'bold', 'italic', 'underline']],
                ['layout', ['ul', 'ol', 'paragraph']],
                ['misc', ['undo', 'redo', 'fullscreen', 'codeview']]
            ]
        });

        $('.submit').on('click', function(event) {

            var submitValue = $(this).parents().find('.text-editor').val();

            if (submitValue === "<p><br></p>") {
                $(this).parents().find('.text-editor').val('');
            }
            return;      
        });

        var saveImagesPosition = function (container, token) {
            var images = $(container).find('img');
            var data = {};
            var token = token;
            
            $.each(images, function (index, val) {
                var image = {
                    "_token" : token,
                    "id" : $(val).attr('data-id'),
                    "position" : index
                };
                data[index] = image;
            });
            $.each(data, function(index, val) {
                 
                $.ajax({
                    url: '/splash-images/' + data[index]['id'],
                    type: 'PUT',
                    dataType: 'JSON',
                    data: data[index]
                })
                .done(function() {
                    console.log("success");
                })
                .fail(function() {
                    console.log("error");
                })
                .always(function() {
                    console.log("complete");
                });
            });
            
            return;
        };

        var savePagesPosition = function (container, token) {
            var pages = $(container).find('li.js-page');
            var data = {};
            var token = token;
            
            $.each(pages, function (index, val) {
                var page = {
                    "_token" : token,
                    "id" : $(val).attr('data-id'),
                    "position" : index
                };
                data[index] = page;
            });
            $.each(data, function(index, val) {
                 
                $.ajax({
                    url: '/pages/' + data[index]['id'],
                    type: 'PUT',
                    dataType: 'JSON',
                    data: data[index]
                })
                .done(function() {
                    console.log("success");
                })
                .fail(function() {
                    console.log("error");
                })
                .always(function() {
                    console.log("complete");
                });
            });
            
            return;
        };
        // Sortable Splash
        $('#slideShowContainer, #pageListContainer').sortable().bind('sortupdate', function (e, ui) {
            $(this).parents().closest('section').find('.sortSubmit')
                .removeAttr('disabled')
                .toggleClass('btn-default')
                .addClass('btn-success');
        });

        // SlideShowSubmit
        $('#slideShowSubmit').on('click', function (event) {
            var token = $(this).parents('form').find('input[name=_token]').attr('value');
            
            saveImagesPosition('#slideShowContainer', token);
        });

        // Page Position Submit
        $('#pageListSubmit').on('click', function (event) {
            var token = $(this).parents('form').find('input[name=_token]').attr('value');
            
            savePagesPosition('#pageListContainer', token);
        });
    }

});
